body {
  padding-top: 70px;
  padding-bottom: 30px;
}

.theme-dropdown .dropdown-menu {
  display: block;
  position: static;
  margin-bottom: 20px;
}

.theme-showcase > p > .btn {
  margin: 5px 0;
}