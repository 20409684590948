/**
 * BxSlider v4.0 - Fully loaded, responsive content slider
 * http://bxslider.com
 *
 * Written by: Steven Wanderski, 2012
 * http://stevenwanderski.com
 * (while drinking Belgian ales and listening to jazz)
 *
 * CEO and founder of bxCreative, LTD
 * http://bxcreative.com
 */


/** RESET AND LAYOUT
===================================*/
.bx-wrapper {
	position: relative;
	padding: 0;
	*zoom: 1;
}

.bx-wrapper img {
	width: 100%;
	display: block;
}

/** THEME
===================================*/
.bxslider {
	left:1px;
	margin-left:-1px;
	position:relative;
}

.bx-wrapper .bx-viewport {
	padding: 0;
	background: none;
}

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
	position: absolute;
	bottom: -30px;
	width: 100%;
	display:none;
}

/* LOADER */

.bx-wrapper .bx-loading {
	min-height: 50px;
	/*background: url(images/bx_loader.gif) center center no-repeat #fff;*/
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2000;
}

/* PAGER */

.bx-wrapper .bx-pager {
	text-align: center;
	font-size: .85em;
	font-family: Arial;
	font-weight: bold;
	color: #666;
	padding-top: 20px;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
	display: inline-block;
	*zoom: 1;
	*display: inline;
}

.bx-wrapper .bx-pager.bx-default-pager a {
	background: #666;
	text-indent: -9999px;
	display: block;
	width: 10px;
	height: 10px;
	margin: 0 5px;
	outline: 0;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
	background: #000;
}

/* DIRECTION CONTROLS (NEXT / PREV) */

.bx-wrapper .bx-prev {
	left: -74px;
	/*background: url(images/left.png) no-repeat;*/
}
.bx-wrapper .bx-next {
	left: -50px;
	/*background: url(images/right.png) no-repeat;*/
}

.bx-wrapper .bx-prev:hover {
	/*background: url(images/left-hover.png) no-repeat;*/
}

.bx-wrapper .bx-next:hover {
	/*background: url(images/right-hover.png) no-repeat;*/
}

.bx-wrapper .bx-controls-direction a {
	position: absolute;
	top: 4px;
	outline: 0;
	width: 24px;
	height: 24px;
	z-index: 9;
	text-indent: -9999px;
	border-radius: 50px;
}
/*
.bx-wrapper .bx-prev {
	left: 10px;
	background: url(images/controls.png) no-repeat 0 -32px;
}
.bx-wrapper .bx-next {
	right: 10px;
	background: url(images/controls.png) no-repeat -43px -32px;
}
.bx-wrapper .bx-prev:hover {
	background-position: 0 0;
}

.bx-wrapper .bx-next:hover {
	background-position: -43px 0;
}

.bx-wrapper .bx-controls-direction a {
	position: absolute;
	top: 50%;
	margin-top: -16px;
	outline: 0;
	width: 32px;
	height: 32px;
	text-indent: -9999px;
	z-index: 9999;
}
*/

.bx-wrapper .bx-controls-direction a.disabled {
	display: none;
}

/* AUTO CONTROLS (START / STOP) */

.bx-wrapper .bx-controls-auto {
	text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
	display: block;
	text-indent: -9999px;
	width: 10px;
	height: 11px;
	outline: 0;
	/*background: url(images/controls.png) -86px -11px no-repeat;*/
	margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
	background-position: -86px 0;
}

.bx-wrapper .bx-controls-auto .bx-stop {
	display: block;
	text-indent: -9999px;
	width: 9px;
	height: 11px;
	outline: 0;
	/*background: url(images/controls.png) -86px -44px no-repeat;*/
	margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
	background-position: -86px -33px;
}

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
	text-align: left;
	width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
	right: 0;
	width: 35px;
}

/* IMAGE CAPTIONS */

.bx-wrapper .bx-caption {
	position: absolute;
	bottom: 0;
	left: 0;
	background: #666\9;
	background: rgba(80, 80, 80, 0.75);
	width: 100%;
}

.bx-wrapper .bx-caption span {
	color: #fff;
	font-family: Arial;
	display: block;
	font-size: .85em;
	padding: 10px;
}

/*Recent Works*/
.recent-work ul.bxslider li b {
	color: #656565;
	font-size: 13px;
	font-weight: 200;
	font-style: normal;
}

.recent-work ul.bxslider li em {
	display: block;
	overflow: hidden;
	position: relative;
}

.recent-work ul.bxslider li strong {
	color: #000;
	display: block;
	font-size: 16px;
	font-weight: 300;
}

.recent-work ul.bxslider li:hover b,
.recent-work ul.bxslider li:hover strong {
	color: #fff;
}

.recent-work ul.bxslider li a.bxslider-block {
	padding: 10px;
	display: block;
	background: #fcfcfc;
}

.recent-work ul.bxslider li:hover a.bxslider-block {
	background: #0da3e2;
    transition: all 0.9s ease;
    -o-transition: all 0.9s ease;
    -moz-transition: all 0.9s ease;
    -webkit-transition: all 0.9s ease;		
}

/*Hover Effects*/
.recent-work ul.bxslider li i.icon-hover-1,
.recent-work ul.bxslider li i.icon-hover-2 {
	top: 50%;	
	color: #fff;
	padding: 16px 30px 16px 18px;
	font-size: 16px;
	position: absolute;
	margin-top: -1.5em;
	border-radius: 50%;
	/*background: url("../img/black-opacity.png") repeat !important;*/
}

.recent-work ul.bxslider li i.icon-hover-1:hover,
.recent-work ul.bxslider li i.icon-hover-2:hover {
	color: #0da3e2;
}

.recent-work ul.bxslider li i.icon-hover-1 {
	left: -100px;
}
.recent-work ul.bxslider li i.icon-hover-2 {
	right: -100px;
}

.recent-work ul.bxslider li:hover i.icon-hover-1 {
	left: 30%;
}

.recent-work ul.bxslider li:hover i.icon-hover-2 {
	right: 30%;
}

.recent-work ul.bxslider li:hover i.icon-hover-1,
.recent-work ul.bxslider li:hover i.icon-hover-2 {
    transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -webkit-transition: all 0.6s ease;	
}