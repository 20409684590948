/*panel*/
.panel {
    box-shadow: -2 0px 0px rgba(0, 0, 0, 0.0) !important;
}

.panel-default {
    border-color: #f4f4f4;
}

.panel-default > .panel-heading {
    background-color: #f4f4f4;
    color: #797979;
}

.panel-default > .panel-heading + .panel-collapse .panel-body {
    border-top-color: #F4F4F4;
}


/*label*/
.label-default {
    background-color: #a1a1a1;
}

.label-primary {
    background-color: #59ace2;
}

.label-success {
    background-color: #A9D86E;
}

.label-info {
    background-color: #8175c7;
}

.label-warning {
    background-color: #FCB322;
}

.label-danger {
    background-color: #FF6C60;
}

.label-inverse {
    background-color: #344860;
}

/*text color*/

.text-danger {
    color: #FF6C60;
}

.text-muted {
    color: #a1a1a1;
}

.text-primary {
    color: #59ace2;
}

.text-warning {
    color: #FCB322;
}

.text-success {
    color: #A9D86E;
}

.text-info {
    color: #8175c7;
}

/*modal*/

.modal-content {
    box-shadow: none;
    border: none;
}

.modal-header {
    background: #00A8B3;
    color: #fff;
    border-radius: 1px 1px 0 0;
    -webkit-border-radius: 1px 1px 0 0;
    border-bottom: none;
}

.modal-header .close {
    margin-top: 0;
}

/*text input*/

.form-control {
    border: 1px solid #e2e2e4;
    box-shadow: none;
    color: #c2c2c2;
}


.form-control:focus, #focusedInput {
    border: 1px solid #517397;
    box-shadow: none;
}

.form-horizontal .control-label {
    font-weight: 300;
    font-size: 14px;
    text-align: left;
}

input, textarea, select, button {
    outline: none !important;
}

/*list*/

ul {
    padding-left: 0;
}

/*button*/

.btn-default {
    background-color: #bec3c7;
    border-color: #bec3c7;
    color: #fff;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
    background-color: #b0b5b9;
    border-color: #b0b5b9;
    color: #fff;
}

.btn-primary {
    background-color: #41cac0;
    border-color: #41cac0;
    color: #FFFFFF;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
    background-color: #39b2a9;
    border-color: #39b2a9;
    color: #FFFFFF;
}

.btn-success {
    background-color: #1abc9c;
    border-color: #1abc9c;
    color: #FFFFFF;
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .open .dropdown-toggle.btn-success {
    background-color: #6dbb4a;
    border-color: #6dbb4a;
    color: #FFFFFF;
}

.btn-info {
    background-color: #58c9f3;
    border-color: #58c9f3;
    color: #FFFFFF;
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .open .dropdown-toggle.btn-info {
    background-color: #53bee6;
    border-color: #53BEE6;
    color: #FFFFFF;
}

.btn-warning {
    background-color: #f1c500;
    border-color: #f1c500;
    color: #FFFFFF;
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .open .dropdown-toggle.btn-warning {
    background-color: #e4ba00;
    border-color: #e4ba00;
    color: #FFFFFF;
}

.btn-danger {
    background-color: #48C9B0;
    border-color: #48C9B0;
    color: #FFFFFF;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger {
    background-color: #ec6459;
    border-color: #ec6459;
    color: #FFFFFF;
}


.btn:active, .btn.active {
    box-shadow: none;
}

/*Rounded Button*/

.btn-round {
    border-radius: 30px;
    -webkit-border-radius: 30px;
}

/*shadow button*/

.btn-shadow.btn-default {
    box-shadow: 0 4px #9c9c9c;
}
.btn-shadow.btn-primary {
    box-shadow: 0 4px #29b392;
}
.btn-shadow.btn-success {
    box-shadow: 0 4px #61a642;
}
.btn-shadow.btn-info {
    box-shadow: 0 4px #1caadc;
}
.btn-shadow.btn-warning {
    box-shadow: 0 4px #cab03f;
}
.btn-shadow.btn-danger {
    box-shadow: 0 4px #d1595a;
}


/*dropdown shadow*/


/*dropdown select bg*/
.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    background-color: #495d74;
    color: #FFFFFF;
    text-decoration: none;
}

/*split dropdown btn*/

.btn-white {
    background-clip: padding-box;
    background-color: #FFFFFF;
    border-color: rgba(150, 160, 180, 0.3);
    box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.05) inset;
}

/*breadcrumbs*/


/*tab*/

.nav-tabs > li > a {
    margin-right: 1px;
}

/*nav justified*/

.nav-justified {
    width: auto !important;
}

.nav-justified li:last-child > a:hover, .nav-justified li.active:last-child > a {
    /* border-radius: 0 4px 0 0 !important; */
    /* -webkit-border-radius: 0 4px 0 0 !important; */
}

/*pagination*/

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #1abc9c;
    border-color: #16a085;
    color: #FFFFFF;
    cursor: default;
    z-index: 2;
}


