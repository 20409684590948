/*.slider-head-before::before {
    content:'';
    position: absolute;
    top: -49px;
    left: -96px;
    width: 1112px;
    height: 391px;
    background: transparent url(../img/parallax-slider/images/header-white-bg.png) repeat 0% 0%;
}*/

section.contact-infos {
    border: 1px solid #eee;
    margin-bottom: 15px;
    padding: 20px;
}

.btn {
    display: inline-block;
    padding: 10px 16px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.428571429;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.btn.btn-primary {
    background-color: #c53c2d;
    background: linear-gradient(135deg, #ff1900 0%,#c20d00 100%);
    border: none;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.mauto {
    margin: 0 auto;
    display: block;
}

.da-slider {
	width: 100%;
	height: 500px;
	min-width: 520px;
	position: relative;
	margin: 0 auto;
	overflow: hidden;
	background: transparent url("../img/parallax-slider/images/main-bg.jpg") repeat 0% 0%;
	/*box-shadow: 0px 1px 1px rgba(0,0,0,0.2), 0px -2px 1px #fff;*/
	-webkit-transition: background-position 1s ease-out 0.3s;
	-moz-transition: background-position 1s ease-out 0.3s;
	-o-transition: background-position 1s ease-out 0.3s;
	-ms-transition: background-position 1s ease-out 0.3s;
	transition: background-position 1s ease-out 0.3s;
	z-index: 1;
}
.da-slide{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	font-family: 'BebasNeueRegular', 'Arial Narrow', Arial, sans-serif;
	text-align: left;
}
.da-slide-current{
	z-index: 1000;
}
.da-slider-fb .da-slide{
	left: 100%;
}
.da-slider-fb  .da-slide.da-slide-current{
	left: 0px;
}

.da-img .da-img-1,
.da-img .da-img-2,
.da-img .da-img-3 {
    position: absolute;
    top: 60%;
    width: 397px;
    height: 106px;
    text-align: center;
    margin-left: -8px;
    margin-top: 95px;
    font-size: 40px;
}

.da-slide h2,
.da-slide p,
.da-slide .da-link,
.da-slide .da-img{
	position: absolute;
	opacity: 0;
	left: 110%;
	z-index: 999;
}
.da-slider-fb .da-slide h2,
.da-slider-fb .da-slide p,
.da-slider-fb .da-slide .da-link{
	left: 10%;
	opacity: 1;
}
.da-slider-fb .da-slide .da-img{
	left: 60%;
	opacity: .9;
}
.da-slide h2{
	color: #fff;
	font-size: 40px;
	/*width: 32%;*/
	top: 60px;
	white-space: nowrap;
	z-index: 10;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.35);
	font-family: 'Economica', Arial, sans-serif;
	font-weight: 700;
	margin-left: -8%;
}
.da-slide p{
	width: 45%;
	top: 260px;
	color: #f5f5f5;
	font-size: 18px;
	line-height: 26px;
	height: 80px;
	overflow: hidden;
	font-style: italic;
	font-family: 'Economica', Arial, sans-serif;
	font-weight: 400;
	font-style: italic;
	margin-left: -8%;
	text-transform: uppercase;
}
.da-slide .da-img img{
	text-align: center;
	/*width: 30%;*/
	/*top: 70px;*/
	padding-top: 0px;
	/*height: 256px;*/
	line-height: 320px;
	left: 110%; /*60%*/
	background: transparent;
	/*height: 443px;*/
	width: 476px;
	border-radius: 0;
}

.da-slide .da-link{
	top: 390px; /*depends on p height*/
	border-radius: 3px;
	box-shadow: 0px 1px 1px rgba(0,0,0,0.1);
	color: #fff;
	/*text-shadow: 1px 1px 1px rgba(0,0,0,0.2);*/
	/*border: 8px solid rgba(255,255,255,0.8);*/
	padding: 10px 5px;
	font-size: 18px;
	line-height: 30px;
	width: 120px;
	text-align: center;
	background: #cc3d2e;
	margin-left: -8%;
}
.da-slide .da-link:hover{
	background: rgba(197, 60, 45, 0.80);
}
.da-dots{
	width: 100%;
	position: absolute;
	text-align: center;
	left: 0;
    bottom: 10px;
	z-index: 2000;
	-moz-user-select: none;
	-webkit-user-select: none;
	opacity: 1;
}

.da-dots span{
	display: inline-block;
	position: relative;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: rgba(0,0,0,0.25);
	margin: 3px;
	cursor: pointer;
	box-shadow:
		1px 1px 1px rgba(0,0,0,0.1) inset,
		1px 1px 1px rgba(255,255,255,0.1);
	opacity: 1;
}
.da-dots span.da-dots-current:after{
	content: '';
	width: 6px;
	height: 6px;
	position: absolute;
	top: 2px;
	left: 2px;
	border-radius: 50%;
	background: rgba(197,60,45,0.5);
}
.da-arrows{
	-moz-user-select: none;
	-webkit-user-select: none;
}
.da-arrows span{
	position: absolute;
	top: 50%;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	/*background: #000;*/
	cursor: pointer;
	z-index: 2000;
	opacity: 0.5;
	/*box-shadow:
		1px 1px 1px rgba(0,0,0,0.1) inset,
		1px 1px 1px rgba(255,255,255,0.1);*/
	-webkit-transition: opacity 0.4s ease-in-out-out 0.2s;
	-moz-transition: opacity 0.4s ease-in-out-out 0.2s;
	-o-transition: opacity 0.4s ease-in-out-out 0.2s;
	-ms-transition: opacity 0.4s ease-in-out-out 0.2s;
	transition: opacity 0.4s ease-in-out-out 0.2s;
}
.da-slider:hover .da-arrows span{
	opacity: 1;
}
.da-arrows span:after{
	content: '';
	position: absolute;
	width: 20px;
	height: 20px;
	top: 5px;
	left: 5px;
	background: transparent url("../img/parallax-slider/images/arrows.png") no-repeat top left;
	border-radius: 50%;
	box-shadow: 1px 1px 2px rgba(0,0,0,0.1);
}
.da-arrows span:hover:after{
	background: transparent url("../img/parallax-slider/images/arrows-h.png") no-repeat top left;
	box-shadow: 1px 1px 4px rgba(0,0,0,0.3);
}
.da-arrows span:active:after{
	box-shadow: 1px 1px 1px rgba(255,255,255,0.1);
}
.da-arrows span.da-arrows-next:after{
	background-position: top right;
}
.da-arrows span.da-arrows-prev{
	left: 15px;
}
.da-arrows span.da-arrows-next{
	right: 15px;
}

.da-slide-current h2,
.da-slide-current p,
.da-slide-current .da-link{
	left: 10%;
	opacity: 1;
}
.da-slide-current .da-img{
	left: 60%;
	opacity: 1;
}
/* Animation classes and animations */

/* Slide in from the right*/
.da-slide-fromright h2{
	-webkit-animation: fromRightAnim1 0.6s ease-in-out 0.8s both;
	-moz-animation: fromRightAnim1 0.6s ease-in-out 0.8s both;
	-o-animation: fromRightAnim1 0.6s ease-in-out 0.8s both;
	-ms-animation: fromRightAnim1 0.6s ease-in-out 0.8s both;
	animation: fromRightAnim1 0.6s ease-in-out 0.8s both;
}
.da-slide-fromright p{
	-webkit-animation: fromRightAnim2 0.6s ease-in-out 0.8s both;
	-moz-animation: fromRightAnim2 0.6s ease-in-out 0.8s both;
	-o-animation: fromRightAnim2 0.6s ease-in-out 0.8s both;
	-ms-animation: fromRightAnim2 0.6s ease-in-out 0.8s both;
	animation: fromRightAnim2 0.6s ease-in-out 0.8s both;
}
.da-slide-fromright .da-link{
	-webkit-animation: fromRightAnim3 0.4s ease-in-out 1.2s both;
	-moz-animation: fromRightAnim3 0.4s ease-in-out 1.2s both;
	-o-animation: fromRightAnim3 0.4s ease-in-out 1.2s both;
	-ms-animation: fromRightAnim3 0.4s ease-in-out 1.2s both;
	animation: fromRightAnim3 0.4s ease-in-out 1.2s both;
}
.da-slide-fromright .da-img{
	-webkit-animation: fromRightAnim4 0.6s ease-in-out 0.8s both;
	-moz-animation: fromRightAnim4 0.6s ease-in-out 0.8s both;
	-o-animation: fromRightAnim4 0.6s ease-in-out 0.8s both;
	-ms-animation: fromRightAnim4 0.6s ease-in-out 0.8s both;
	animation: fromRightAnim4 0.6s ease-in-out 0.8s both;
}
@-webkit-keyframes fromRightAnim1{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-webkit-keyframes fromRightAnim2{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-webkit-keyframes fromRightAnim3{
	0%{ left: 110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-webkit-keyframes fromRightAnim4{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 60%; opacity: 1; }
}

@-moz-keyframes fromRightAnim1{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-moz-keyframes fromRightAnim2{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-moz-keyframes fromRightAnim3{
	0%{ left: 110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-moz-keyframes fromRightAnim4{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 60%; opacity: 1; }
}

@-o-keyframes fromRightAnim1{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-o-keyframes fromRightAnim2{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-o-keyframes fromRightAnim3{
	0%{ left: 110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-o-keyframes fromRightAnim4{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 60%; opacity: 1; }
}

@-ms-keyframes fromRightAnim1{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-ms-keyframes fromRightAnim2{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-ms-keyframes fromRightAnim3{
	0%{ left: 110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-ms-keyframes fromRightAnim4{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 60%; opacity: 1; }
}

@keyframes fromRightAnim1{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@keyframes fromRightAnim2{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@keyframes fromRightAnim3{
	0%{ left: 110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@keyframes fromRightAnim4{
	0%{ left: 110%; opacity: 0; }
	100%{ left: 60%; opacity: 1; }
}
/* Slide in from the left*/
.da-slide-fromleft h2{
	-webkit-animation: fromLeftAnim1 0.6s ease-in-out 0.6s both;
	-moz-animation: fromLeftAnim1 0.6s ease-in-out 0.6s both;
	-o-animation: fromLeftAnim1 0.6s ease-in-out 0.6s both;
	-ms-animation: fromLeftAnim1 0.6s ease-in-out 0.6s both;
	animation: fromLeftAnim1 0.6s ease-in-out 0.6s both;
}
.da-slide-fromleft p{
	-webkit-animation: fromLeftAnim2 0.6s ease-in-out 0.6s both;
	-moz-animation: fromLeftAnim2 0.6s ease-in-out 0.6s both;
	-o-animation: fromLeftAnim2 0.6s ease-in-out 0.6s both;
	-ms-animation: fromLeftAnim2 0.6s ease-in-out 0.6s both;
	animation: fromLeftAnim2 0.6s ease-in-out 0.6s both;
}
.da-slide-fromleft .da-link{
	-webkit-animation: fromLeftAnim3 0.4s ease-in-out 1.2s both;
	-moz-animation: fromLeftAnim3 0.4s ease-in-out 1.2s both;
	-o-animation: fromLeftAnim3 0.4s ease-in-out 1.2s both;
	-ms-animation: fromLeftAnim3 0.4s ease-in-out 1.2s both;
	animation: fromLeftAnim3 0.4s ease-in-out 1.2s both;
}
.da-slide-fromleft .da-img{
	-webkit-animation: fromLeftAnim4 0.6s ease-in-out 0.6s both;
	-moz-animation: fromLeftAnim4 0.6s ease-in-out 0.6s both;
	-o-animation: fromLeftAnim4 0.6s ease-in-out 0.6s both;
	-ms-animation: fromLeftAnim4 0.6s ease-in-out 0.6s both;
	animation: fromLeftAnim4 0.6s ease-in-out 0.6s both;
}
@-webkit-keyframes fromLeftAnim1{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-webkit-keyframes fromLeftAnim2{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-webkit-keyframes fromLeftAnim3{
	0%{ left: -110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-webkit-keyframes fromLeftAnim4{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 60%; opacity: 1; }
}

@-moz-keyframes fromLeftAnim1{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-moz-keyframes fromLeftAnim2{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-moz-keyframes fromLeftAnim3{
	0%{ left: -110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-moz-keyframes fromLeftAnim4{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 60%; opacity: 1; }
}

@-o-keyframes fromLeftAnim1{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-o-keyframes fromLeftAnim2{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-o-keyframes fromLeftAnim3{
	0%{ left: -110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-o-keyframes fromLeftAnim4{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 60%; opacity: 1; }
}

@-ms-keyframes fromLeftAnim1{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-ms-keyframes fromLeftAnim2{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-ms-keyframes fromLeftAnim3{
	0%{ left: -110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@-ms-keyframes fromLeftAnim4{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 60%; opacity: 1; }
}

@keyframes fromLeftAnim1{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@keyframes fromLeftAnim2{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@keyframes fromLeftAnim3{
	0%{ left: -110%; opacity: 0; }
	1%{ left: 10%; opacity: 0; }
	100%{ left: 10%; opacity: 1; }
}
@keyframes fromLeftAnim4{
	0%{ left: -110%; opacity: 0; }
	100%{ left: 60%; opacity: 1; }
}
/* Slide out to the right */
.da-slide-toright h2{
	-webkit-animation: toRightAnim1 0.6s ease-in-out 0.6s both;
	-moz-animation: toRightAnim1 0.6s ease-in-out 0.6s both;
	-o-animation: toRightAnim1 0.6s ease-in-out 0.6s both;
	-ms-animation: toRightAnim1 0.6s ease-in-out 0.6s both;
	animation: toRightAnim1 0.6s ease-in-out 0.6s both;
}
.da-slide-toright p{
	-webkit-animation: toRightAnim2 0.6s ease-in-out 0.3s both;
	-moz-animation: toRightAnim2 0.6s ease-in-out 0.3s both;
	-o-animation: toRightAnim2 0.6s ease-in-out 0.3s both;
	-ms-animation: toRightAnim2 0.6s ease-in-out 0.3s both;
	animation: toRightAnim2 0.6s ease-in-out 0.3s both;
}
.da-slide-toright .da-link{
	-webkit-animation: toRightAnim3 0.4s ease-in-out both;
	-moz-animation: toRightAnim3 0.4s ease-in-out both;
	-o-animation: toRightAnim3 0.4s ease-in-out both;
	-ms-animation: toRightAnim3 0.4s ease-in-out both;
	animation: toRightAnim3 0.4s ease-in-out both;
}
.da-slide-toright .da-img{
	-webkit-animation: toRightAnim4 0.6s ease-in-out both;
	-moz-animation: toRightAnim4 0.6s ease-in-out both;
	-o-animation: toRightAnim4 0.6s ease-in-out both;
	-ms-animation: toRightAnim4 0.6s ease-in-out both;
	animation: toRightAnim4 0.6s ease-in-out both;
}
@-webkit-keyframes toRightAnim1{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@-webkit-keyframes toRightAnim2{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@-webkit-keyframes toRightAnim3{
	0%{ left: 10%;  opacity: 1; }
	99%{ left: 10%; opacity: 0; }
	100%{ left: 100%; opacity: 0; }
}
@-webkit-keyframes toRightAnim4{
	0%{ left: 60%;  opacity: 1; }
	30%{ left: 55%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}

@-moz-keyframes toRightAnim1{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@-moz-keyframes toRightAnim2{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@-moz-keyframes toRightAnim3{
	0%{ left: 10%;  opacity: 1; }
	99%{ left: 10%; opacity: 0; }
	100%{ left: 100%; opacity: 0; }
}
@-moz-keyframes toRightAnim4{
	0%{ left: 60%;  opacity: 1; }
	30%{ left: 55%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}

@-o-keyframes toRightAnim1{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@-o-keyframes toRightAnim2{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@-o-keyframes toRightAnim3{
	0%{ left: 10%;  opacity: 1; }
	99%{ left: 10%; opacity: 0; }
	100%{ left: 100%; opacity: 0; }
}
@-o-keyframes toRightAnim4{
	0%{ left: 60%;  opacity: 1; }
	30%{ left: 55%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}

@-ms-keyframes toRightAnim1{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@-ms-keyframes toRightAnim2{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@-ms-keyframes toRightAnim3{
	0%{ left: 10%;  opacity: 1; }
	99%{ left: 10%; opacity: 0; }
	100%{ left: 100%; opacity: 0; }
}
@-ms-keyframes toRightAnim4{
	0%{ left: 60%;  opacity: 1; }
	30%{ left: 55%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}

@keyframes toRightAnim1{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@keyframes toRightAnim2{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
@keyframes toRightAnim3{
	0%{ left: 10%;  opacity: 1; }
	99%{ left: 10%; opacity: 0; }
	100%{ left: 100%; opacity: 0; }
}
@keyframes toRightAnim4{
	0%{ left: 60%;  opacity: 1; }
	30%{ left: 55%;  opacity: 1; }
	100%{ left: 100%; opacity: 0; }
}
/* Slide out to the left*/
.da-slide-toleft h2{
	-webkit-animation: toLeftAnim1 0.6s ease-in-out both;
	-moz-animation: toLeftAnim1 0.6s ease-in-out both;
	-o-animation: toLeftAnim1 0.6s ease-in-out both;
	-ms-animation: toLeftAnim1 0.6s ease-in-out both;
	animation: toLeftAnim1 0.6s ease-in-out both;
}
.da-slide-toleft p{
	-webkit-animation: toLeftAnim2 0.6s ease-in-out 0.3s both;
	-moz-animation: toLeftAnim2 0.6s ease-in-out 0.3s both;
	-o-animation: toLeftAnim2 0.6s ease-in-out 0.3s both;
	-ms-animation: toLeftAnim2 0.6s ease-in-out 0.3s both;
	animation: toLeftAnim2 0.6s ease-in-out 0.3s both;
}
.da-slide-toleft .da-link{
	-webkit-animation: toLeftAnim3 0.6s ease-in-out 0.6s both;
	-moz-animation: toLeftAnim3 0.6s ease-in-out 0.6s both;
	-o-animation: toLeftAnim3 0.6s ease-in-out 0.6s both;
	-ms-animation: toLeftAnim3 0.6s ease-in-out 0.6s both;
	animation: toLeftAnim3 0.6s ease-in-out 0.6s both;
}
.da-slide-toleft .da-img{
	-webkit-animation: toLeftAnim4 0.6s ease-in-out 0.5s both;
	-moz-animation: toLeftAnim4 0.6s ease-in-out 0.5s both;
	-o-animation: toLeftAnim4 0.6s ease-in-out 0.5s both;
	-ms-animation: toLeftAnim4 0.6s ease-in-out 0.5s both;
	animation: toLeftAnim4 0.6s ease-in-out 0.5s both;
}
@-webkit-keyframes toLeftAnim1{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-webkit-keyframes toLeftAnim2{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-webkit-keyframes toLeftAnim3{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-webkit-keyframes toLeftAnim4{
	0%{ left: 60%;  opacity: 1; }
	70%{ left: 30%;  opacity: 0; }
	100%{ left: -50%; opacity: 0; }
}

@-moz-keyframes toLeftAnim1{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-moz-keyframes toLeftAnim2{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-moz-keyframes toLeftAnim3{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-moz-keyframes toLeftAnim4{
	0%{ left: 60%;  opacity: 1; }
	70%{ left: 30%;  opacity: 0; }
	100%{ left: -50%; opacity: 0; }
}

@-o-keyframes toLeftAnim1{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-o-keyframes toLeftAnim2{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-o-keyframes toLeftAnim3{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-o-keyframes toLeftAnim4{
	0%{ left: 60%;  opacity: 1; }
	70%{ left: 30%;  opacity: 0; }
	100%{ left: -50%; opacity: 0; }
}

@-ms-keyframes toLeftAnim1{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-ms-keyframes toLeftAnim2{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-ms-keyframes toLeftAnim3{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@-ms-keyframes toLeftAnim4{
	0%{ left: 60%;  opacity: 1; }
	70%{ left: 30%;  opacity: 0; }
	100%{ left: -50%; opacity: 0; }
}

@keyframes toLeftAnim1{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@keyframes toLeftAnim2{
	0%{ left: 10%;  opacity: 1; }
	30%{ left: 15%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@keyframes toLeftAnim3{
	0%{ left: 10%;  opacity: 1; }
	100%{ left: -50%; opacity: 0; }
}
@keyframes toLeftAnim4{
	0%{ left: 60%;  opacity: 1; }
	70%{ left: 30%;  opacity: 0; }
	100%{ left: -50%; opacity: 0; }
}