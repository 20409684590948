body {

}
.langselect {
    position: absolute;
    right: 30px;
    top: 30px;
    padding: 5px;
    border: solid 1px #eeeeee;
    border-radius: 5px;
    background: #f5f5f5;
    z-index: 99999;
}

.langselect a {
    float: left;
    padding: 2px;
}

.clients-list li {
    float: left;
    list-style: none;
    position: relative;
    width: auto;
    margin: 3px;
}

.clients-list li img {
    width: 183px;
    display: block;
    height: auto;
}

@media (max-width:960px) {

    .langselect {
        position: relative;
        left: 10px;
        top: 10px;
        padding: 5px;
        border: solid 1px #eeeeee;
        border-radius: 5px;
        background: #f5f5f5;
        width: 110px;
    }
    .langselect a {
        float: none!important;
        padding: 2px;
    }

}